import { Injectable, Signal, computed, signal } from '@angular/core';
import { Breadcrumb } from '@common/interface/common.interface';
import { IMerchantInfo } from 'src/app/pages/auth/interface/login.interface';
import { CustomLocalStorageService } from '../custom-local-storage/custom-local-storage.service';
import { Store } from '@common/interface/dropdown-store.interface';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private breadcrumbSignal = signal([{}] as Breadcrumb[]);
  private languageSignal = signal('' as string);
  private merchantInfoSignal = signal({} as any);
  storesSignal = signal([] as Store[]);
  private loadingPageSignal = signal(false as boolean);
  private loadingTable = signal(false as boolean);

  constructor(private _customLocalStorageService: CustomLocalStorageService) {}

  // Breadcrumb State
  setBreadcrumb(value: Breadcrumb[]): void {
    this.breadcrumbSignal.set(value);
  }

  getBreadcrumb(): Signal<Breadcrumb[]> {
    return computed(() => this.breadcrumbSignal());
  }

  // Language State
  setLanguage(lang: string): void {
    this.languageSignal.set(lang);
    this._customLocalStorageService.setItem('language', lang);
  }

  getLanguage(): Signal<string> {
    return computed(() => this.languageSignal());
  }

  getLocalLanguage(): string {
    return this._customLocalStorageService.getItem('language') as string;
  }

  // Merchant Info
  setMerchantInfo(info: IMerchantInfo): void {
    this.merchantInfoSignal.set(info);
    this._customLocalStorageService.setItem(
      'merchant-info',
      JSON.stringify(info)
    );
  }

  getMerchantInfo(): IMerchantInfo {
    const merchantInfo: IMerchantInfo = JSON.parse(
      this._customLocalStorageService.getItem('merchant-info') as string
    );

    return merchantInfo;
  }

  // Stores
  setStores(val: Store[]): void {
    this.storesSignal.set(val);
  }

  // Store Id
  getStoreId(): number {
    const merchantInfo: IMerchantInfo = JSON.parse(
      this._customLocalStorageService.getItem('merchant-info') as string
    );

    return merchantInfo?.storeId;
  }

  //  Loading Page
  setLoadingPage(val: boolean): void {
    this.loadingPageSignal.set(val);
  }

  getLoadingPage(): Signal<boolean> {
    return computed(() => this.loadingPageSignal());
  }

  //  Loading Table
  setLoadingTable(val: boolean): void {
    this.loadingTable.set(val);
  }

  getLoadingTable(): Signal<boolean> {
    return computed(() => this.loadingTable());
  }
}
